import React, { useState } from 'react'
import { useTranslation } from 'react-i18next-new'
import styled from 'styled-components'
import axios from 'axios'

import Form from '../reusable_components/forms/Form'
import TextInput from '../reusable_components/forms/TextInput'
import Button from '../reusable_components/buttons/Button'
import { DynamicPageSectionRow, PageSidePaddingWrapper } from '../reusable_components/layout/PageComponents'
import { theme } from '../../utils/theme'
import { P2 } from '../reusable_components/Typography'
import useRoutes from '../../hooks/useRoutes'

const Background = styled.div`
	background: ${theme.colors.lightGray};
	border-radius: 2px;
	color: black;
	display: flex;
	flex-direction: column;
	padding: 0 ${theme.paddings.large};
`

function NewsletterSignup({ user }) {
	const [submitted, setSubmitted] = useState(false)
	const routes = useRoutes()
	const { t } = useTranslation()
	const signupToNewsLetter = (inputValues, onSubmitFinished) => {
		axios({
			method: 'post',
			url: '/api/v1/public/newsletter',
			data: {
				user: { email: inputValues.email.toLowerCase() },
			},
		})
			.then(response => {
				if (response.status === 200) {
					setSubmitted(true)
				} else {
					onSubmitFinished(t('copy:checkoutPage.genericError'))
				}
			})
			.catch(error => {
				onSubmitFinished(t('copy:checkoutPage.genericError'))
			})
	}
	return (
		<Background>
			<PageSidePaddingWrapper>
				<DynamicPageSectionRow margins={{ inner: theme.margins.largeInt, outer: theme.margins.XLargeInt }}>
					<div>
						<h2>{t('copy:marketplacesPage.newsletterTitle')}</h2>
						<p>{t('copy:marketplacesPage.newsletterText')}</p>
					</div>
					<div>
						{submitted ? (
							<>
								<P2>{t('copy:marketplacesPage.newsletterSubmitted')}</P2>
								<br />
							</>
						) : (
							<Form onSubmit={signupToNewsLetter} nonWhiteBackground>
								<TextInput
									value={user.email}
									placeholder="Email"
									name="email"
									skipLabel
									type="email"
									required
									skipRequiredAsterisk
								/>
								<Button buttonType="RECTANGLE">Ok</Button>
							</Form>
						)}
					</div>
				</DynamicPageSectionRow>
			</PageSidePaddingWrapper>
		</Background>
	)
}

export default NewsletterSignup
